import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import MusicView from '@/views/MusicView.vue'
import ShowsView from '@/views/ShowsView.vue'
import ShowView from '@/views/ShowView.vue'
import VideosView from '@/views/VideosView.vue'
import ReleaseView from '@/views/ReleaseView.vue'
import RedirectView from '@/views/RedirectView.vue'
// import { useLoaderState } from '@/store/isLoading'


const views: RouteRecordRaw[] = [
  { path: '/', component: HomeView },
  { path: '/home', name: 'home', component: HomeView },
  { path: '/music', name: 'music', component: MusicView },
  { path: '/music/:release', name: 'release', component: ReleaseView },
  { path: '/shows', name: 'shows', component: ShowsView },
  { path: '/shows/:event/:slug', name: 'show', component: ShowView },
  { path: '/videos', name: 'videos', component: VideosView },
]

const privateViews: RouteRecordRaw[] = [
  { path: '/admin/setlist', name: 'setlist', component: () => import('@/views/SetlistView.vue') },
  { path: '/admin/qrcode', name: 'qrcode', component: () => import('@/views/QRCodeView.vue') },
]

const redirects: RouteRecordRaw[] = [
  { path: '/shop', name: 'shop', redirect: 'https://www.freewebstore.org/disease-illusion-online-store' },
  { path: '/shop/product/:product', name: 'shopProduct', redirect: 'https://www.freewebstore.org/disease-illusion-online-store' },
  { path: '/store', name: 'store', redirect: 'https://www.freewebstore.org/disease-illusion-online-store' },
  { path: '/store/product/:product', name: 'storeProduct', redirect: 'https://www.freewebstore.org/disease-illusion-online-store' },
  { path: '/merch', name: 'merch', redirect: 'https://www.freewebstore.org/disease-illusion-online-store' },
  { path: '/merch/product/:product', name: 'merchProduct', redirect: 'https://www.freewebstore.org/disease-illusion-online-store' },
  { path: '/booking', name: 'booking', redirect: 'https://www.rockonagency.com/' },
  {
    path: '/listen',
    name: 'listen',
    redirect: 'https://social.tunecore.com/linkShare?linkid=HFnyR7QcsOhWCxCXWeo2FA'
  },
  {
    path: '/realitybeyondtheillusionoflife',
    name: 'realitybeyondtheillusionoflife',
    redirect: 'https://ffm.to/realitybeyondtheillusionoflife'
  },
  { path: '/backworld', name: 'backworld', redirect: 'https://ffm.to/diseaseillusionbackworld' },
  { path: '/redwine', name: 'redwine', redirect: 'https://ffm.to/redwinestainedcheek' },
  { path: '/afterthestorm', name: 'afterthestorm', redirect: 'https://ffm.to/afterthestormthatnevercame' },
  {
    path: '/plasticocean',
    name: 'plasticocean',
    redirect: 'https://social.tunecore.com/linkShare?linkid=HFnyR7QcsOhWCxCXWeo2FA'
  },
  {
    path: '/iamtheenemy',
    name: 'iamtheenemy',
    redirect: 'https://social.tunecore.com/linkShare?linkid=fj6sQIIcr6KFMC-EXcGGFA'
  },
  {
    path: '/glassandsteel',
    name: 'glassandsteel',
    redirect: 'https://social.tunecore.com/linkShare?linkid=3raQUILp1YUc08D1w7RKUQ'
  },
  {
    path: '/invisible',
    name: 'invisible',
    redirect: 'https://social.tunecore.com/linkShare?linkid=GR1hoxZvbEx7XJTlaOI42A'
  },
  {
    path: '/no_ground',
    name: 'no_ground',
    redirect: 'https://open.spotify.com/artist/7r8uZKJDrwLyy3HoD90FKC'
  },
].map(route => {
  return {
    path: route.path,
    name: route.name,
    component: RedirectView,
    props: {
      url: route.redirect,
    }
  }
})

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...views,
    ...redirects,
    ...privateViews,
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundView },
  ],
})

// router.beforeEach((_, __, next): void => {
//   const isLoading = useLoaderState()
//   const { changeStateTrue } = isLoading
//   changeStateTrue()
//   next()
// })
//
// router.afterEach((): void => {
//   const isLoading = useLoaderState()
//   const { changeStateFalse } = isLoading
//   changeStateFalse()
// })

export default router
